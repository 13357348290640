<template>
  <div>
    <b-card>
      <filter-data :get-report="getReport" />
    </b-card>
    <b-card>
      <report-chart />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ReportChart from '@/views/Reports/Csi/Users/ReportChart.vue'
import FilterData from '@/views/Reports/Csi/Users/FilterData.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    ReportChart,
    FilterData,
  },
  computed: {
    filterData() {
      return this.$store.getters['csiUsersReport/getFilterData']
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('csiUsersReport/report', this.filterData)
    },
  },
}
</script>
