<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="report.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ReportChart',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      // series: [{
      //   name: 'Necip Koç',
      //   data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      // }, {
      //   name: 'Tonguç Koç',
      //   data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      // }, {
      //   name: 'Mükerrem',
      //   data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      // }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['OCAK', 'ŞUBAT', 'MART', 'NİSAN', 'MAYIS', 'HAZİRAN', 'TEMMUZ', 'AĞUSTOS', 'EYLÜL', 'EKİM', 'KASIM', 'ARALIK'],
        },
        yaxis: {
          title: {
            text: 'Danışman Genel Skor',
          },
          labels: {
            show: true,
            formatter(val) {
              return val
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val} Skor`
            },
          },
        },
      },
    }
  },
  computed: {
    report() {
      return this.$store.getters['csiUsersReport/getReport']
    },
  },
}
</script>
