<template>
  <div>
    <v-select
      v-model="filterData.id_com_department"
      :options="options.departments"
      label="title"
      :reduce="item => item.id"
      placeholder="Departman"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'Department',
  components: {
    vSelect,
  },
  computed: {
    options() {
      return this.$store.getters['csiUsersReport/getFilterItems']
    },
    filterData() {
      return this.$store.getters['csiUsersReport/getFilterData']
    },
  },
}
</script>
